<template>
  <div id="new-case-study" class="w-full bg-gray-900 mx-auto pt-10 pb-16 px-1 md:px-0">
    <div class="max-w-sm mx-auto mb-12 text-center font-bold font-sans border-orange-400 border-b-4 pb-4 tracking-widest text-gray-100">
      <h3>Case Study | First Time Show Hosts</h3>
    </div>
    <div class="text-gray-100">
      <h1 class="max-w-4xl mx-auto pb-6 text-3xl sm:text-4xl font-bold leading-none sm:leading-tighter sm:text-center">First time running a livestock show? Learn how Showman helped the Mitten State ABGA Fall Show.</h1>
      <div class="flex flex-col-reverse md:flex-row justify-center items-center mt-6">
        <div class="md:flex-1 md:mr-6 px-2 md:px-0 max-w-xl">
          <p class="text-2xl pb-1 mb-3 border-b border-gray-400">The origins of the Mitten State ABGA Fall Show</p>
          <div class="text-lg leading-5">
            <p>
              In 2018 three friends united by a passion for raising boer goats formed a partnership to help each other grow and improve in the Boer Goat industry. Thus the Central Michigan Boer Goat Breeders was born.
            </p>
            <p class="mt-4">
              With the goals of growing the industry in Central Michigan, hosting a sanctioned ABGA show seemed like a great first opportunity to bring together breeders in the area. For many it would be the first time meeting and also be an introduction to the ABGA show ring.
            </p>
            <p class="mt-4">
              But where to start? How do you handle entries? Won't check-in be crazy? Where do we record class lists and results? Having never run a show before it seemed like a large hurdle to climb.
            </p>
          </div>
        </div>
        <img class="rounded-full w-2/3 pb-4 md:max-w-xs" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_500,f_auto/v1634743885/mitten_backdrop.jpg">
      </div>
      <div class="flex flex-col md:flex-row justify-center items-center mt-6">
        <img class="max-w-xs md:mr-6 mb-4 md:mb-0" src="@/assets/mitten_showring.jpg">
        <div class="flex-1  max-w-xl px-2 md:px-0">
          <p class="text-2xl pb-1 mb-3 border-b border-gray-400">Showman to the rescue</p>
          <div class="text-lg leading-5">
            <p>
              Showman provided a stable online interface for exhibitors to quickly and accurately submit all of their entry information. Accepting payments was no hassle with money automatically deposited right in our bank account leading up to the show.
            </p>
            <p class="mt-4">
              The day of the show went off without a hitch, the check-in process was easy and intuitive. We were able to go over and get comfortable with the check-in process before the show.
            </p>
            <p class="mt-4">
              Results were compiled quickly and accurately and we were able to finalize and submit our results by the Monday following the show!
            </p>
          </div>
        </div>
      </div>
      <div class="my-12 text-center px-2 md:px-0">
        <p class="text-2xl border-b max-w-sm mx-auto mb-4">The Final Verdict</p>
        <!--<div class="flex justify-center mt-3 max-w-md mx-auto">
          <span class="text-4xl -mr-2">"</span>
          <p class="text-lg leading-none">
            I would not put on another show without using Showman.
            Thank you for making this App!
            I don’t know how people were doing this before.
          </p>
          <span class="text-4xl -ml-2">"</span>
        </div>-->
        <div class="lg:flex-shrink-1 lg:max-w-lg lg:w-full lg:px-5 mx-auto">
          <figure class="relative px-4 pt-5 pb-6 bg-white rounded-lg shadow-lg lg:px-8 lg:pt-8 lg:pb-6">
            <div class="absolute top-0 left-0 pl-2 pt-2">
              <svg class="h-24 w-24" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 48.45C8 31.247 22.46 16.601 38.787 8l3.032 4.882c-10.495 6.51-17.26 15.11-17.26 24.177s7.93 13.716 17.027 15.11C42.986 63.562 36.921 71 26.426 71 17.329 71 8 63.793 8 48.45zm80-35.568L84.968 8C68.64 16.602 54.18 31.247 54.18 48.45 54.18 63.793 63.51 71 72.606 71c10.496 0 16.56-7.44 15.16-18.83-9.095-1.395-17.026-6.044-17.026-15.111 0-9.066 6.764-17.668 17.26-24.177z" fill="#BEE3F8" fill-opacity=".5"></path>
              </svg>
            </div>
            <blockquote class="relative text-sm font-medium text-gray-800 lg:text-base">
              <p>
                We won’t put on a show without it! Having Showman kept our records clean and our show organized.
                We were able to compile and finalize our show results the day after the show!
                Thank you for developing this tool that will be so helpful to many!
              </p>
            </blockquote>
            <figcaption class="mt-6 flex">
              <div class="w-16 mr-1">
                <img class="h-12 w-12 object-cover rounded-full" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_50,f_auto/v1634743885/mitten_backdrop.jpg" alt="Olivier Lacan's avatar">
              </div>
              <div class="text-sm">
                <p class="font-semibold text-indigo-700">Lisa Douglas & Jodie Fath</p>
                <p class="text-gray-700">Central Michigan Boer Goat Board Members after their first time hosting a livestock show</p>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
